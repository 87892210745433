<template>
  <div>
    <b-card class="mb-1" no-body header-tag="header" footer-tag="footer" bg-variant="secondary">
      <b-card-header header-bg-variant="secondary" class="p-1">
        <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '153')">?</b-badge>
        <b-row>
          <b-col sm="12" md="1">
            <b-button v-b-toggle="'anatomy_model'" variant="secondary" style="padding-right: 1px; padding-left: 1px;">
              <img src="@/icon/W3D_humanbody.png" style="width: 2.5em; cursor: pointer; padding: 0px;" />
            </b-button>
            <!-- <strong>Anatomik Bölge</strong> -->{{ calculate_anatomy_region() }}
            <!--             <small v-if="selected_anatomy_region.parent"> 
              (
              <strong v-if="user && user.permissions_result && user.permissions_result.indexOf('wisdom') !== -1" style="color: red;">
                parent = {{ selected_anatomy_region.parent }}
              </strong>
              ) 
            </small>
 --></b-col>
          <b-col sm="12" md="11">
            <v-select v-model="selected_reg" placeholder="Lütfen Anatomik Bölge Seçimi Yapınız" :options="all_in_one_list" style="width: 100%"></v-select>
          </b-col>
        </b-row>
      </b-card-header>
      <b-collapse v-if="option_data.male_anatomy" id="anatomy_model" :visible="false">
        <!--         <span style="color: red;" v-if="user && user.permissions_result && user.permissions_result.indexOf('wisdom') !== -1" title="Değişiklikleri kaydet butonuna tıklamadığınız sürece kaydedilmeyecektir. Herhangi bir bölge oluştururken kendi başına anlam ifade edecek şekilde oluşturunuz. Çünkü bir üstündeki bölge ile birlikte kayıtta kullanılmamaktadır.">
          UYARI
        </span>
 -->
        <b-row>
          <b-col sm="12" lg="2" v-if="user && user && user.permissions_result && user.permissions_result.indexOf('wisdom') !== -1">
            <b-dropdown left variant="primary">
              <b-dropdown-item @click="reset_changes()">Değişiklikleri sıfırla</b-dropdown-item>
              <b-dropdown-item @click="save_changes()">Değişiklikleri kaydet</b-dropdown-item>
              <b-dropdown-item @click="wis_op_take_caption()">wisdom tüm başlıkları al</b-dropdown-item>
              <b-dropdown-item @click="wis_op_take_label()">wisdom tüm label değerlerini al</b-dropdown-item>
              <b-dropdown-item @click="wis_op()">wisdom işlemi</b-dropdown-item>
            </b-dropdown>
          </b-col>
          <b-col sm="12" lg="10">
            <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '154')">?</b-badge>
            <b-form-checkbox name="all_in_one" v-model="view_mode" value="all_in_one">Tüm Bölgeler</b-form-checkbox>
            <b-form-checkbox name="region_sub_region" v-model="view_mode" value="region_sub_region">Bölge -> Alt Bölge</b-form-checkbox>
          </b-col>
        </b-row>
        <b-row v-if="!patient_follow">
          <b-col sm="12" md="3" lg="3"> Aile Grubu </b-col>
          <b-col sm="12" md="9" lg="9">
            <b-input-group>
              <v-select v-model="selected_parent" :options="option_data.male_anatomy.parent_list" style="width: 80%"></v-select>
              <b-input-group-append v-if="user && user.permissions_result && user.permissions_result.indexOf('wisdom') !== -1">
                <b-dropdown left variant="success">
                  <b-dropdown-item @click="edit_parent = false; show_parent = true; parent_data = {caption: '', label: '', 'translation': {tr: '', en: ''}}">yeni aile</b-dropdown-item>
                  <b-dropdown-item @click="edit_parent = true; show_parent = true; parent_data = selected_anatomy_parent">Bu bölgeyi düzenle</b-dropdown-item>
                  <b-dropdown-item v-if="selected_anatomy_region && selected_parent" @click="add_parent_to_selected_anatomy_region()">seçili aileyi, seçili anatomi bölgesine ekle</b-dropdown-item>
                </b-dropdown>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row v-if="show_parent">
          <b-col cols="2">
            <strong>Yeni parent</strong>
          </b-col>
          <b-col cols="6">
            <b-row>
              <b-col cols="12">
                <b-form-group label="value" label-for="value" label-cols="3">
                  <b-form-input type="text" id="value" v-model="parent_data.value"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group label="label" label-for="label" label-cols="3">
                  <b-form-input type="text" id="label" v-model="parent_data.label"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group label="tr" label-for="translation.tr" label-cols="3">
                  <b-form-input type="text" id="translation.tr" v-model="parent_data.translation.tr"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group label="en" label-for="translation.en" label-cols="3">
                  <b-form-input type="text" id="translation.en" v-model="parent_data.translation.en"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-button variant="success" @click="show_parent = false; parent_data_add_edit()">{{ $t('wdm16.4061') }}</b-button>
                <b-button variant="primary" @click="show_parent = false">{{ $t('wdm16.3975') }}</b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <template v-if="view_mode === 'region_sub_region'">
          <b-row>
            <b-col sm="12" md="3" lg="3"> Sistemler </b-col>
            <b-col sm="12" md="9" lg="9">
              <b-input-group>
                <v-select v-model="data.system" :options="option_data.male_anatomy.system.list" style="width: 80%"></v-select>
                <b-input-group-append v-if="user && user.permissions_result && user.permissions_result.indexOf('wisdom') !== -1">
                  <b-dropdown left variant="success" v-if="data.system">
                    <b-dropdown-item v-if="!show_new_system_add" @click="show_new_system_add = true; new_system = {caption: '', label: '', 'translation': {tr: '', en: ''}, 'parent': []}">Bu satıra ekle</b-dropdown-item>
                    <b-dropdown-item v-if="!show_system_edit" @click="open_edit_system_mode()">Bu bölgeyi düzenle</b-dropdown-item>
                    <b-dropdown-item v-if="!show_system_edit" @click="show_new_sub = true; new_system = {caption: '', label: '', 'translation': {tr: '', en: ''}, 'parent': []}; selected_sub = 'sub_1';">Alt bölgesine ekle</b-dropdown-item>
                    <b-dropdown-item v-if="selected_anatomy_region && selected_parent" @click="op_from_selected_to_sub('system', 'add_selected_parent')">seçili aileyi, seçili anatomi bölgeye ve altındaki bölgelere ekle</b-dropdown-item>
                    <b-dropdown-item v-if="selected_anatomy_region && selected_parent" @click="op_from_selected_to_sub('system', 'delete_selected_parent')">seçili aileyi, seçili anatomik bölge ve altından sil</b-dropdown-item>
                  </b-dropdown>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
          <!-- sub sub sub sub sub sub sub -->
          <b-row v-if="data.system && option_data.male_anatomy.system.sub[data.system.value] && !show_new_system_add">
            <b-col sm="12" md="3" lg="3"> 1. alt bölge </b-col>
            <b-col sm="12" md="9" lg="9">
              <b-input-group>
                <v-select v-model="data.sub_1" :options="option_data.male_anatomy.system.sub[data.system.value].list" style="width: 80%"></v-select>
                <b-input-group-append v-if="user && user.permissions_result && user.permissions_result.indexOf('wisdom') !== -1">
                  <b-dropdown left variant="success" v-if="data.sub_1">
                    <b-dropdown-item v-if="!show_new_sub" @click="show_new_sub = true; new_system = {caption: '', label: '', 'translation': {tr: '', en: ''}, 'parent': []}; selected_sub = 'sub_1';">Bu bölgeye ekle</b-dropdown-item>
                    <b-dropdown-item v-if="!show_system_edit" @click="open_edit_sub_mode('sub_1')">Bu bölgeyi düzenle</b-dropdown-item>
                    <b-dropdown-item @click="delete_sub_region('sub_1')">Bu bölgeyi altındaki tüm alanlarla birlikte sil</b-dropdown-item>
                    <b-dropdown-item v-if="!show_new_sub" @click="show_new_sub = true; new_system = {caption: '', label: '', 'translation': {tr: '', en: ''}, 'parent': []}; selected_sub = 'sub_2';">Alt bölgesine ekle</b-dropdown-item>
                    <b-dropdown-item v-if="selected_anatomy_region && selected_parent" @click="op_from_selected_to_sub('sub_1', 'add_selected_parent')">seçili aileyi, seçili anatomi bölgeye ve altındaki bölgelere ekle</b-dropdown-item>
                    <b-dropdown-item v-if="selected_anatomy_region && selected_parent" @click="op_from_selected_to_sub('sub_1', 'delete_selected_parent')">seçili aileyi, seçili anatomik bölge ve altından sil</b-dropdown-item>
                  </b-dropdown>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
          <b-row v-if="data.sub_1 && option_data.male_anatomy.system.sub[data.system.value].sub[data.sub_1.value] && !show_new_system_add">
            <b-col sm="12" md="3" lg="3">2. alt bölge</b-col>
            <b-col sm="12" md="9" lg="9">
              <b-input-group>
                <v-select v-model="data.sub_2" :options="option_data.male_anatomy.system.sub[data.system.value].sub[data.sub_1.value].list" style="width: 80%"></v-select>
                <b-input-group-append v-if="user && user.permissions_result && user.permissions_result.indexOf('wisdom') !== -1">
                  <b-dropdown left variant="success" v-if="data.sub_2">
                    <b-dropdown-item v-if="!show_new_sub" @click="show_new_sub = true; new_system = {caption: '', label: '', 'translation': {tr: '', en: ''}, 'parent': []}; selected_sub = 'sub_2';">Bu bölgeye ekle</b-dropdown-item>
                    <b-dropdown-item v-if="!show_system_edit" @click="open_edit_sub_mode('sub_2')">Bu bölgeyi düzenle</b-dropdown-item>
                    <b-dropdown-item @click="delete_sub_region('sub_2')">Bu bölgeyi altındaki tüm alanlarla birlikte sil</b-dropdown-item>
                    <b-dropdown-item v-if="!show_new_sub" @click="show_new_sub = true; new_system = {caption: '', label: '', 'translation': {tr: '', en: ''}, 'parent': []}; selected_sub = 'sub_3';">Alt bölgesine ekle</b-dropdown-item>
                    <b-dropdown-item v-if="selected_anatomy_region && selected_parent" @click="op_from_selected_to_sub('sub_2', 'add_selected_parent')">seçili aileyi, seçili anatomi bölgeye ve altındaki bölgelere ekle</b-dropdown-item>
                    <b-dropdown-item v-if="selected_anatomy_region && selected_parent" @click="op_from_selected_to_sub('sub_2', 'delete_selected_parent')">seçili aileyi, seçili anatomik bölge ve altından sil</b-dropdown-item>
                  </b-dropdown>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
          <b-row v-if="data.sub_2 && option_data.male_anatomy.system.sub[data.system.value].sub[data.sub_1.value].sub[data.sub_2.value] && !show_new_system_add">
            <b-col sm="12" md="3" lg="3">3. alt bölge</b-col>
            <b-col sm="12" md="9" lg="9">
              <b-input-group>
                <v-select v-model="data.sub_3" :options="option_data.male_anatomy.system.sub[data.system.value].sub[data.sub_1.value].sub[data.sub_2.value].list" style="width: 80%"></v-select>
                <b-input-group-append v-if="user && user.permissions_result && user.permissions_result.indexOf('wisdom') !== -1">
                  <b-dropdown left variant="success" v-if="data.sub_3">
                    <b-dropdown-item v-if="!show_new_sub" @click="show_new_sub = true; new_system = {caption: '', label: '', 'translation': {tr: '', en: ''}, 'parent': []}; selected_sub = 'sub_3';">Bu bölgeye ekle</b-dropdown-item>
                    <b-dropdown-item v-if="!show_system_edit" @click="open_edit_sub_mode('sub_3')">Bu bölgeyi düzenle</b-dropdown-item>
                    <b-dropdown-item @click="delete_sub_region('sub_3')">Bu bölgeyi altındaki tüm alanlarla birlikte sil</b-dropdown-item>
                    <b-dropdown-item v-if="!show_new_sub" @click="show_new_sub = true; new_system = {caption: '', label: '', 'translation': {tr: '', en: ''}, 'parent': []}; selected_sub = 'sub_4';">Alt bölgesine ekle</b-dropdown-item>
                    <b-dropdown-item v-if="selected_anatomy_region && selected_parent" @click="op_from_selected_to_sub('sub_3', 'add_selected_parent')">seçili aileyi, seçili anatomi bölgeye ve altındaki bölgelere ekle</b-dropdown-item>
                    <b-dropdown-item v-if="selected_anatomy_region && selected_parent" @click="op_from_selected_to_sub('sub_3', 'delete_selected_parent')">seçili aileyi, seçili anatomik bölge ve altından sil</b-dropdown-item>
                  </b-dropdown>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
          <b-row v-if="data.sub_3 && option_data.male_anatomy.system.sub[data.system.value].sub[data.sub_1.value].sub[data.sub_2.value].sub[data.sub_3.value] && !show_new_system_add">
            <b-col sm="12" md="3" lg="3">4. alt bölge</b-col>
            <b-col sm="12" md="9" lg="9">
              <b-input-group>
                <v-select v-model="data.sub_4" :options="option_data.male_anatomy.system.sub[data.system.value].sub[data.sub_1.value].sub[data.sub_2.value].sub[data.sub_3.value].list" style="width: 80%"></v-select>
                <b-input-group-append v-if="user && user.permissions_result && user.permissions_result.indexOf('wisdom') !== -1">
                  <b-dropdown left variant="success" v-if="data.sub_4">
                    <b-dropdown-item v-if="!show_new_sub" @click="show_new_sub = true; new_system = {caption: '', label: '', 'translation': {tr: '', en: ''}, 'parent': []}; selected_sub = 'sub_4';">Bu bölgeye ekle</b-dropdown-item>
                    <b-dropdown-item v-if="!show_system_edit" @click="open_edit_sub_mode('sub_4')">Bu bölgeyi düzenle</b-dropdown-item>
                    <b-dropdown-item @click="delete_sub_region('sub_4')">Bu bölgeyi altındaki tüm alanlarla birlikte sil</b-dropdown-item>
                    <b-dropdown-item v-if="!show_new_sub" @click="show_new_sub = true; new_system = {caption: '', label: '', 'translation': {tr: '', en: ''}, 'parent': []}; selected_sub = 'sub_5';">Alt bölgesine ekle</b-dropdown-item>
                    <b-dropdown-item v-if="selected_anatomy_region && selected_parent" @click="op_from_selected_to_sub('sub_4', 'add_selected_parent')">seçili aileyi, seçili anatomi bölgeye ve altındaki bölgelere ekle</b-dropdown-item>
                    <b-dropdown-item v-if="selected_anatomy_region && selected_parent" @click="op_from_selected_to_sub('sub_4', 'delete_selected_parent')">seçili aileyi, seçili anatomik bölge ve altından sil</b-dropdown-item>
                  </b-dropdown>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
          <b-row v-if="data.sub_4 && option_data.male_anatomy.system.sub[data.system.value].sub[data.sub_1.value].sub[data.sub_2.value].sub[data.sub_3.value].sub[data.sub_4.value] && !show_new_system_add">
            <b-col sm="12" md="3" lg="3">5. alt bölge</b-col>
            <b-col sm="12" md="9" lg="9">
              <b-input-group>
                <v-select v-model="data.sub_5" :options="option_data.male_anatomy.system.sub[data.system.value].sub[data.sub_1.value].sub[data.sub_2.value].sub[data.sub_3.value].sub[data.sub_4.value].list" style="width: 80%"></v-select>
                <b-input-group-append v-if="user && user.permissions_result && user.permissions_result.indexOf('wisdom') !== -1">
                  <b-dropdown left variant="success" v-if="data.sub_5">
                    <b-dropdown-item v-if="!show_new_sub" @click="show_new_sub = true; new_system = {caption: '', label: '', 'translation': {tr: '', en: ''}, 'parent': []}; selected_sub = 'sub_5';">Bu bölgeye ekle</b-dropdown-item>
                    <b-dropdown-item v-if="!show_system_edit" @click="open_edit_sub_mode('sub_5')">Bu bölgeyi düzenle</b-dropdown-item>
                    <b-dropdown-item @click="delete_sub_region('sub_5')">Bu bölgeyi sil</b-dropdown-item>
                    <b-dropdown-item v-if="selected_anatomy_region && selected_parent" @click="op_from_selected_to_sub('sub_5', 'add_selected_parent')">seçili aileyi, seçili anatomi bölgeye ve altındaki bölgelere ekle</b-dropdown-item>
                    <b-dropdown-item v-if="selected_anatomy_region && selected_parent" @click="op_from_selected_to_sub('sub_5', 'delete_selected_parent')">seçili aileyi, seçili anatomik bölge ve altından sil</b-dropdown-item>
                  </b-dropdown>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
          <!-- sistem sistem sistem ekleme düzenleme alanı -->
          <b-row v-if="show_new_system_add && user.permissions_result && user.permissions_result.indexOf('wisdom') !== -1">
            <b-col cols="2">
              <strong>Yeni sistem (wisdom)</strong>
            </b-col>
            <b-col cols="6">
              <b-row>
                <b-col cols="12">
                  <b-form-group label="value" label-for="value" label-cols="3">
                    <b-form-input type="text" id="value" v-model="new_system.value"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group label="label" label-for="label" label-cols="3">
                    <b-form-input type="text" id="label" v-model="new_system.label"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group label="tr" label-for="translation.tr" label-cols="3">
                    <b-form-input type="text" id="translation.tr" v-model="new_system.translation.tr"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group label="en" label-for="translation.en" label-cols="3">
                    <b-form-input type="text" id="translation.en" v-model="new_system.translation.en"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="3"></b-col>
                <b-col cols="9">
                  <b-button variant="success" @click="show_new_system_add = false; add_system_wisdom()">kaydet (wisdom)</b-button>
                  <b-button variant="primary" @click="show_new_system_add = false">{{ $t('wdm16.3975') }}</b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="show_system_edit && user.permissions_result && user.permissions_result.indexOf('wisdom') !== -1">
            <b-col cols="2">
              <strong>Sistem düzenle (wisdom)</strong>
            </b-col>
            <b-col cols="6">
              <b-row>
                <b-col cols="12">
                  <b-form-group label="value" label-for="value" label-cols="3">
                    <b-form-input type="text" id="value" v-model="edit_data.value"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group label="label" label-for="label" label-cols="3">
                    <b-form-input type="text" id="label" v-model="edit_data.label"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group label="tr" label-for="translation.tr" label-cols="3">
                    <b-form-input type="text" id="translation.tr" v-model="edit_data.translation.tr"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group label="en" label-for="translation.en" label-cols="3">
                    <b-form-input type="text" id="translation.en" v-model="edit_data.translation.en"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="3"></b-col>
                <b-col cols="9">
                  <b-button variant="success" @click="edit_system_wisdom()">kaydet (wisdom)</b-button>
                  <b-button variant="primary" @click="show_system_edit = false">{{ $t('wdm16.3975') }}</b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="show_new_system_add">
            <b-col cols="2">
              <strong>Yeni sistem</strong>
            </b-col>
            <b-col cols="6">
              <b-row>
                <b-col cols="12">
                  <b-form-group label="label" label-for="label" label-cols="3">
                    <b-form-input type="text" id="label" v-model="new_system.label"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="3"></b-col>
                <b-col cols="9">
                  <b-button variant="success" @click="show_new_system_add = false; add_system()">kaydet (wisdom)</b-button>
                  <b-button variant="primary" @click="show_new_system_add = false">{{ $t('wdm16.3975') }}</b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <!-- sub düzenleme ekleme alanı -->
          <b-row v-if="show_new_sub && user.permissions_result && user.permissions_result.indexOf('wisdom') !== -1">
            <b-col cols="2">
              <strong style="color: red;">Yeni {{ selected_sub.split('_')[1] }}. alt bölge (wisdom)</strong>
            </b-col>
            <b-col cols="6">
              <b-row>
                <b-col cols="12">
                  <b-form-group label="value" label-for="value" label-cols="3">
                    <b-form-input type="text" id="value" v-model="new_system.value"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group label="label" label-for="label" label-cols="3">
                    <b-form-input type="text" id="label" v-model="new_system.label"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group label="tr" label-for="translation.tr" label-cols="3">
                    <b-form-input type="text" id="translation.tr" v-model="new_system.translation.tr"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group label="en" label-for="translation.en" label-cols="3">
                    <b-form-input type="text" id="translation.en" v-model="new_system.translation.en"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="3"></b-col>
                <b-col cols="9">
                  <b-button variant="success" @click="show_new_sub = false; add_sub_wisdom()">kaydet (wisdom)</b-button>
                  <b-button variant="primary" @click="show_new_sub = false">{{ $t('wdm16.3975') }}</b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="show_sub_edit && user.permissions_result && user.permissions_result.indexOf('wisdom') !== -1">
            <b-col cols="2">
              <strong style="color: red;">Düzenle {{ selected_sub.split('_')[1] }}. alt bölge (wisdom)</strong>
            </b-col>
            <b-col cols="6">
              <b-row>
                <b-col cols="12">
                  <b-form-group label="value" label-for="value" label-cols="3">
                    <b-form-input type="text" id="value" v-model="edit_data[selected_sub].value"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group label="label" label-for="label" label-cols="3">
                    <b-form-input type="text" id="label" v-model="edit_data[selected_sub].label"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group label="tr" label-for="translation.tr" label-cols="3">
                    <b-form-input type="text" id="translation.tr" v-model="edit_data[selected_sub].translation.tr"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group label="en" label-for="translation.en" label-cols="3">
                    <b-form-input type="text" id="translation.en" v-model="edit_data[selected_sub].translation.en"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="3"></b-col>
                <b-col cols="9">
                  <b-button variant="success" @click="show_sub_edit = false; edit_sub_wisdom()">kaydet (wisdom)</b-button>
                  <b-button variant="primary" @click="show_sub_edit = false">{{ $t('wdm16.3975') }}</b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </template>
        <template v-else-if="view_mode === 'all_in_one'">
          <b-row>
            <b-col cols="12">
              <b-form-group label="" label-for="selected_reg" :label-cols="2">
                <v-select v-model="selected_reg" :options="all_in_one_list" style="width: 80%"></v-select>
              </b-form-group>
            </b-col>
          </b-row>
        </template>
        <!--           <div class="form-group row" v-if="user && user.permissions_result && user.permissions_result.indexOf('wisdom') !== -1">
            <div class="col-2"> <strong>wisdom çalışma alanı</strong> </div>
            <div class="col-10">
              <textarea v-model="result_text" style="width: 100%;"></textarea>
            </div>
            </div>-->
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { mapGetters } from "vuex";
import OptionService from "@/services/option";
import store from '@/store';
// import moment from 'moment';

export default {
  name: "Anatomy",
  props: {
    selected_anatomy_region: {
      type: Object,
      required: false,
      default: () => ({ label: '', value: '' })
    },
    mother_children: {
      type: Object,
      required: false
    },
    selected_anatomy_parent: {
      type: Object,
      required: false,
      default: () => ({ label: '', value: '' })
    },
    param_change: {
      type: Object,
      required: false
    },
    patient_follow: {
      type: Boolean,
      required: false,
      default: false
    },
    external_patient_data: {
      type: Object,
      required: false
    }
  },
  created () {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.option_data.male_anatomy = JSON.parse(
      localStorage.getItem("male_anatomy")
    );
    this.all_in_one_list = JSON.parse(
      localStorage.getItem("anatomy_all_in_one")
    );
    if (this.external_patient_data) {
      this.patient = JSON.parse(JSON.stringify(this.external_patient_data))
    } else {
      this.patient = JSON.parse(JSON.stringify(this.patient_tmp))
    }
  },
  computed: mapGetters({
    lang: "lang",
    patient_tmp: "patient_selected",
    help: 'help'
  }),
  data () {
    return {
      patient: {},
      store: store,
      parent_data: { caption: "", label: "", translation: { tr: "", en: "" } },
      edit_parent: false,
      show_parent: false,
      selected_parent: "",
      selected_reg: "",
      all_in_one_list: [],
      view_mode: "all_in_one",
      data: {
        system: "",
        sub_1: "",
        sub_2: "",
        sub_3: "",
        sub_4: "",
        sub_5: ""
      },
      result_text: "",
      selected_sub: "",
      edit_data: {
        system: "",
        sub_1: "",
        sub_2: "",
        sub_3: "",
        sub_4: "",
        sub_5: ""
      },
      edit_label: "",
      show_system_edit: false,
      show_new_system_add: false,
      show_new_sub: false,
      show_sub_edit: false,
      option_data: {},
      user: {},
      new_system: { caption: "", label: "", translation: { tr: "", en: "" } }
    };
  },
  methods: {
    wis_op: function () {
      // go to a specific location
      /*
      for (let k in this.option_data.male_anatomy.system.sub['digestive-system'].sub['gaster-duodenum'].list) {
        let data = this.option_data.male_anatomy.system.sub['digestive-system'].sub['gaster-duodenum'].list[k];
        if (data.value.indexOf('duodenum') !== -1) {
          this.option_data.male_anatomy.system.sub['digestive-system'].sub['duodenum'].list.push(data);
        }
      }
      */
      // sistem parent list oluşturuluyor
      /*
      let liste = JSON.parse(JSON.stringify(this.option_data.male_anatomy.system.list));
      this.option_data.male_anatomy.parent_list = [];
      for (let i in liste) {
        this.$delete(liste[i], 'parent');
        this.option_data.male_anatomy.parent_list.push(liste[i]);
      }
      */
      // liste içerisinde geziyoruz
      /*
      for (let sys_ind in this.option_data.male_anatomy.system.list) {
        let sys = this.option_data.male_anatomy.system.list[sys_ind];
        if (this.option_data.male_anatomy.system.sub[sys.value]) {
          for (let sub_1_ind in this.option_data.male_anatomy.system.sub[sys.value].list) {
            let sub_1 = this.option_data.male_anatomy.system.sub[sys.value].list[sub_1_ind];
            if (this.option_data.male_anatomy.system.sub[sys.value].sub[sub_1.value]) {
              for (let sub_2_ind in this.option_data.male_anatomy.system.sub[sys.value].sub[sub_1.value].list) {
                let sub_2 = this.option_data.male_anatomy.system.sub[sys.value].sub[sub_1.value].list[sub_2_ind];
                if (this.option_data.male_anatomy.system.sub[sys.value].sub[sub_1.value].sub[sub_2.value]) {
                  for (let sub_3_ind in this.option_data.male_anatomy.system.sub[sys.value].sub[sub_1.value].sub[sub_2.value].list) {
                    let sub_3 = this.option_data.male_anatomy.system.sub[sys.value].sub[sub_1.value].sub[sub_2.value].list[sub_3_ind];
                    if (this.option_data.male_anatomy.system.sub[sys.value].sub[sub_1.value].sub[sub_2.value].sub[sub_3.value]) {
                      for (let sub_4_ind in this.option_data.male_anatomy.system.sub[sys.value].sub[sub_1.value].sub[sub_2.value].sub[sub_3.value].list) {
                        let sub_4 = this.option_data.male_anatomy.system.sub[sys.value].sub[sub_1.value].sub[sub_2.value].sub[sub_3.value].list[sub_4_ind];
                        if (this.option_data.male_anatomy.system.sub[sys.value].sub[sub_1.value].sub[sub_2.value].sub[sub_3.value].sub[sub_4.value]) {
                          for (let sub_5_ind in this.option_data.male_anatomy.system.sub[sys.value].sub[sub_1.value].sub[sub_2.value].sub[sub_3.value].sub[sub_4.value].list) {
                            let sub_5 = this.option_data.male_anatomy.system.sub[sys.value].sub[sub_1.value].sub[sub_2.value].sub[sub_3.value].sub[sub_4.value].list[sub_5_ind];
                            console.log(sub_5);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      */
      this.$forceUpdate();
    },
    op_from_selected_to_sub: function (sub, operation) {
      let selected_region = this.data[sub];
      let data = this.sub_data_region(sub);
      for (let k1 in data.list) {
        if (data.list[k1].value === selected_region.value) {
          let data_1 = data.list[k1];
          if (operation === "delete_selected_parent") {
            if (
              data_1.parent.indexOf(this.selected_anatomy_parent.value) !== -1
            ) {
              data_1.parent.splice(
                data_1.parent.indexOf(this.selected_anatomy_parent.value),
                1
              );
            }
          } else if (operation === "add_selected_parent") {
            if (
              data_1.parent.indexOf(this.selected_anatomy_parent.value) === -1
            ) {
              data_1.parent.push(this.selected_anatomy_parent.value);
            }
          }
          if (data.sub[data_1.value]) {
            for (let k2 in data.sub[data.list[k1].value].list) {
              let data_2 = data.sub[data.list[k1].value].list[k2];
              if (operation === "delete_selected_parent") {
                if (
                  data_2.parent.indexOf(this.selected_anatomy_parent.value) !==
                  -1
                ) {
                  data_2.parent.splice(
                    data_2.parent.indexOf(this.selected_anatomy_parent.value),
                    1
                  );
                }
              } else if (operation === "add_selected_parent") {
                if (
                  data_2.parent.indexOf(this.selected_anatomy_parent.value) ===
                  -1
                ) {
                  data_2.parent.push(this.selected_anatomy_parent.value);
                }
              }
              if (data.sub[data_1.value].sub[data_2.value]) {
                for (let k3 in data.sub[data_1.value].sub[data_2.value].list) {
                  let data_3 =
                    data.sub[data_1.value].sub[data_2.value].list[k3];
                  if (operation === "delete_selected_parent") {
                    if (
                      data_3.parent.indexOf(
                        this.selected_anatomy_parent.value
                      ) !== -1
                    ) {
                      data_3.parent.splice(
                        data_3.parent.indexOf(
                          this.selected_anatomy_parent.value
                        ),
                        1
                      );
                    }
                  } else if (operation === "add_selected_parent") {
                    if (
                      data_3.parent.indexOf(
                        this.selected_anatomy_parent.value
                      ) === -1
                    ) {
                      data_3.parent.push(this.selected_anatomy_parent.value);
                    }
                  }
                  if (
                    data.sub[data_1.value].sub[data_2.value].sub[data_3.value]
                  ) {
                    for (let k4 in data.sub[data_1.value].sub[data_2.value].sub[
                        data_3.value
                      ].list) {
                      let data_4 =
                        data.sub[data_1.value].sub[data_2.value].sub[
                          data_3.value
                        ].list[k4];
                      if (operation === "delete_selected_parent") {
                        if (
                          data_4.parent.indexOf(
                            this.selected_anatomy_parent.value
                          ) !== -1
                        ) {
                          data_4.parent.splice(
                            data_4.parent.indexOf(
                              this.selected_anatomy_parent.value
                            ),
                            1
                          );
                        }
                      } else if (operation === "add_selected_parent") {
                        if (
                          data_4.parent.indexOf(
                            this.selected_anatomy_parent.value
                          ) === -1
                        ) {
                          data_4.parent.push(
                            this.selected_anatomy_parent.value
                          );
                        }
                      }
                      if (
                        data.sub[data_1.value].sub[data_2.value].sub[
                          data_3.value
                        ].sub[data_4.value]
                      ) {
                        for (let k5 in data.sub[data_1.value].sub[data_2.value]
                            .sub[data_3.value].sub[data_4.value].list) {
                          let data_5 =
                            data.sub[data_1.value].sub[data_2.value].sub[
                              data_3.value
                            ].sub[data_4.value].list[k5];
                          if (operation === "delete_selected_parent") {
                            if (
                              data_5.parent.indexOf(
                                this.selected_anatomy_parent.value
                              ) !== -1
                            ) {
                              data_5.parent.splice(
                                data_5.parent.indexOf(
                                  this.selected_anatomy_parent.value
                                ),
                                1
                              );
                            }
                          } else if (operation === "add_selected_parent") {
                            if (
                              data_5.parent.indexOf(
                                this.selected_anatomy_parent.value
                              ) === -1
                            ) {
                              data_5.parent.push(
                                this.selected_anatomy_parent.value
                              );
                            }
                          }
                          if (
                            data.sub[data_1.value].sub[data_2.value].sub[
                              data_3.value
                            ].sub[data_4.value].sub[data_5.value]
                          ) {
                            for (let k6 in data.sub[data_1.value].sub[
                                data_2.value
                              ].sub[data_3.value].sub[data_4.value].sub[
                                data_5.value
                              ].list) {
                              let data_6 =
                                data.sub[data_1.value].sub[data_2.value].sub[
                                  data_3.value
                                ].sub[data_4.value].sub[data_5.value].list[k6];
                              if (operation === "delete_selected_parent") {
                                if (
                                  data_6.parent.indexOf(
                                    this.selected_anatomy_parent.value
                                  ) !== -1
                                ) {
                                  data_6.parent.splice(
                                    data_6.parent.indexOf(
                                      this.selected_anatomy_parent.value
                                    ),
                                    1
                                  );
                                }
                              } else if (operation === "add_selected_parent") {
                                if (
                                  data_6.parent.indexOf(
                                    this.selected_anatomy_parent.value
                                  ) === -1
                                ) {
                                  data_6.parent.push(
                                    this.selected_anatomy_parent.value
                                  );
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    sub_data_region: function (sub) {
      let data = {};
      if (sub === "system") {
        data = this.option_data.male_anatomy.system;
      }
      if (sub === "sub_1") {
        data = this.option_data.male_anatomy.system.sub[this.data.system.value];
      }
      if (sub === "sub_2") {
        data = this.option_data.male_anatomy.system.sub[this.data.system.value]
          .sub[this.data.sub_1.value];
      }
      if (sub === "sub_3") {
        data = this.option_data.male_anatomy.system.sub[this.data.system.value]
          .sub[this.data.sub_1.value].sub[this.data.sub_2.value];
      }
      if (sub === "sub_4") {
        data = this.option_data.male_anatomy.system.sub[this.data.system.value]
          .sub[this.data.sub_1.value].sub[this.data.sub_2.value].sub[
            this.data.sub_3.value
          ];
      }
      if (sub === "sub_5") {
        data = this.option_data.male_anatomy.system.sub[this.data.system.value]
          .sub[this.data.sub_1.value].sub[this.data.sub_2.value].sub[
            this.data.sub_3.value
          ].sub[this.data.sub_4.value];
      }
      return data;
    },
    add_parent_to_selected_anatomy_region: function () {
      if (this.selected_parent && this.selected_anatomy_region) {
        let sub = "";
        let arr = ["sub_5", "sub_4", "sub_3", "sub_2", "sub_1", "system"];
        for (let a in arr) {
          if (this.data[arr[a]]) {
            sub = arr[a];
            break;
          }
        }
        let data = {};
        if (sub === "system") {
          data = this.option_data.male_anatomy.system;
        }
        if (sub === "sub_1") {
          data = this.option_data.male_anatomy.system.sub[
            this.data.system.value
          ];
        }
        if (sub === "sub_2") {
          data = this.option_data.male_anatomy.system.sub[
            this.data.system.value
          ].sub[this.data.sub_1.value];
        }
        if (sub === "sub_3") {
          data = this.option_data.male_anatomy.system.sub[
            this.data.system.value
          ].sub[this.data.sub_1.value].sub[this.data.sub_2.value];
        }
        if (sub === "sub_4") {
          data = this.option_data.male_anatomy.system.sub[
            this.data.system.value
          ].sub[this.data.sub_1.value].sub[this.data.sub_2.value].sub[
            this.data.sub_3.value
          ];
        }
        if (sub === "sub_5") {
          data = this.option_data.male_anatomy.system.sub[
            this.data.system.value
          ].sub[this.data.sub_1.value].sub[this.data.sub_2.value].sub[
            this.data.sub_3.value
          ].sub[this.data.sub_4.value];
        }
        for (let i in data.list) {
          if (data.list[i].value === this.data[sub].value) {
            if (!data.list[i].parent) {
              data.list[i].parent = [];
            }
            data.list[i].parent.push(this.selected_parent.value);
            break;
          }
        }
      }
      this.$forceUpdate();
    },
    parent_data_add_edit: function () {
      if (this.edit_parent) {
        for (let i in this.option_data.male_anatomy.parent_list) {
          if (
            this.option_data.male_anatomy.parent_list[i].value ===
            this.parent_data.value
          ) {
            this.option_data.male_anatomy.parent_list[i] = this.parent_data;
            break;
          }
        }
      } else {
        this.option_data.male_anatomy.parent_list.push(
          JSON.parse(JSON.stringify(this.parent_data))
        );
      }
      this.selected_parent = JSON.parse(JSON.stringify(this.parent_data));
      this.$forceUpdate();
    },
    create_all_in_one_list: function () {
      this.control_in_all_in_one(this.option_data.male_anatomy.system.list);
      for (let sub_1 in this.option_data.male_anatomy.system.sub) {
        this.control_in_all_in_one(
          this.option_data.male_anatomy.system.sub[sub_1].list
        );
        for (let sub_2 in this.option_data.male_anatomy.system.sub[sub_1].sub) {
          this.control_in_all_in_one(
            this.option_data.male_anatomy.system.sub[sub_1].sub[sub_2].list
          );
          for (let sub_3 in this.option_data.male_anatomy.system.sub[sub_1].sub[
              sub_2
            ].sub) {
            this.control_in_all_in_one(
              this.option_data.male_anatomy.system.sub[sub_1].sub[sub_2].sub[
                sub_3
              ].list
            );
            for (let sub_4 in this.option_data.male_anatomy.system.sub[sub_1]
                .sub[sub_2].sub[sub_3].sub) {
              this.control_in_all_in_one(
                this.option_data.male_anatomy.system.sub[sub_1].sub[sub_2].sub[
                  sub_3
                ].sub[sub_4].list
              );
              for (let sub_5 in this.option_data.male_anatomy.system.sub[sub_1]
                  .sub[sub_2].sub[sub_3].sub[sub_4].sub) {
                this.control_in_all_in_one(
                  this.option_data.male_anatomy.system.sub[sub_1].sub[sub_2]
                  .sub[sub_3].sub[sub_4].sub[sub_5].list
                );
              }
            }
          }
        }
      }
    },
    control_in_all_in_one: function (liste) {
      let det = false;
      for (let i in liste) {
        for (let k in this.all_in_one_list) {
          if (this.all_in_one_list[k].value === liste[i].value) {
            det = true;
            break;
          }
        }
        if (!det) {
          this.all_in_one_list.push(JSON.parse(JSON.stringify(liste[i])));
        }
      }
    },
    calculate_anatomy_region: function () {
      if (this.view_mode === "region_sub_region") {
        let arr = ["sub_5", "sub_4", "sub_3", "sub_2", "sub_1", "system"];
        for (let i in arr) {
          if (this.data[arr[i]]) {
            this.selected_anatomy_region.label = this.data[arr[i]].label;
            this.selected_anatomy_region.value = this.data[arr[i]].value;
            this.selected_anatomy_region.translation = this.data[
              arr[i]
            ].translation;
            this.selected_anatomy_region.parent = this.data[arr[i]].parent;
            break;
          }
        }
        if (this.selected_anatomy_region.value !== this.selected_reg.value) {
          this.selected_reg = this.selected_anatomy_region;
          this.$forceUpdate();
        }
      } else if (this.view_mode === "all_in_one") {
        if (this.selected_reg) {
          this.selected_anatomy_region.label = this.selected_reg.label;
          this.selected_anatomy_region.value = this.selected_reg.value;
          this.selected_anatomy_region.translation = this.selected_reg.translation;
          this.selected_anatomy_region.parent = this.selected_reg.parent;
        }
      }
    },
    delete_sub_region: function (sub) {
      let data = {};
      if (sub === "sub_1") {
        data = this.option_data.male_anatomy.system.sub[this.data.system.value];
      }
      if (sub === "sub_2") {
        data = this.option_data.male_anatomy.system.sub[this.data.system.value]
          .sub[this.data.sub_1.value];
      }
      if (sub === "sub_3") {
        data = this.option_data.male_anatomy.system.sub[this.data.system.value]
          .sub[this.data.sub_1.value].sub[this.data.sub_2.value];
      }
      if (sub === "sub_4") {
        data = this.option_data.male_anatomy.system.sub[this.data.system.value]
          .sub[this.data.sub_1.value].sub[this.data.sub_2.value].sub[
            this.data.sub_3.value
          ];
      }
      if (sub === "sub_5") {
        data = this.option_data.male_anatomy.system.sub[this.data.system.value]
          .sub[this.data.sub_1.value].sub[this.data.sub_2.value].sub[
            this.data.sub_3.value
          ].sub[this.data.sub_4.value];
      }
      for (let i in data.list) {
        if (data.list[i].value === this.data[sub].value) {
          data.list.splice(i, 1);
          this.$delete(data.sub, this.data[sub].value);
          this.data[sub] = "";
          break;
        }
      }
    },
    wis_op_take_caption: function () {
      let data = this.option_data.male_anatomy.system;
      let liste = [];
      for (let i in data.list) {
        let eleman_1 = data.list[i];
        if (liste.indexOf(eleman_1.label) === -1) {
          liste.push(eleman_1.label);
        }
        if (data.sub[eleman_1.value]) {
          for (let k in data.sub[eleman_1.value].list) {
            let eleman_2 = data.sub[eleman_1.value].list[k];
            if (liste.indexOf(eleman_2.label) === -1) {
              liste.push(eleman_2.label);
            }
            if (data.sub[eleman_1.value].sub[eleman_2.value]) {
              for (let z in data.sub[eleman_1.value].sub[eleman_2.value].list) {
                let eleman_3 =
                  data.sub[eleman_1.value].sub[eleman_2.value].list[z];
                if (liste.indexOf(eleman_3.label) === -1) {
                  liste.push(eleman_3.label);
                }
              }
            }
          }
        }
      }
      for (let i in liste) {
        this.result_text += liste[i] + "\n";
      }
      alert("işlem tamamlandı.");
    },
    wis_op_take_label: function () {
      let data = this.option_data.male_anatomy.system;
      let liste = [];
      for (let i in data.list) {
        let eleman_1 = data.list[i];
        if (liste.indexOf(eleman_1.value) === -1) {
          liste.push(eleman_1.value);
        }
        if (data.sub[eleman_1.value]) {
          for (let k in data.sub[eleman_1.value].list) {
            let eleman_2 = data.sub[eleman_1.value].list[k];
            if (liste.indexOf(eleman_2.value) === -1) {
              liste.push(eleman_2.value);
            }
            if (data.sub[eleman_1.value].sub[eleman_2.value]) {
              for (let z in data.sub[eleman_1.value].sub[eleman_2.value].list) {
                let eleman_3 =
                  data.sub[eleman_1.value].sub[eleman_2.value].list[z];
                if (liste.indexOf(eleman_3.value) === -1) {
                  liste.push(eleman_3.value);
                }
              }
            }
          }
        }
      }
      for (let i in liste) {
        this.result_text += liste[i] + "\n";
      }
      alert("işlem tamamlandı.");
    },
    save_changes: function () {
      let data = { 'data': JSON.parse(JSON.stringify(this.option_data)), 'page_type': 'main' };
      OptionService.save(data)
        .then(resp => {
          let msg = '';
          if (resp.data.status === 'success') {
            this.option_data = Object.assign({}, this.option_data, resp.data.result);
            msg = this.$t('wdm16.12661');
          } else {
            msg = 'Error: ' + resp.data.message;
          }
          let modal_data = { 'type': 'alert', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': msg, 'centered': true, 'title': this.$t('wdm16.12656') };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        });
    },
    reset_changes: function () {
      let data = { option_list: ["male_anatomy"], 'page_type': 'main' };
      OptionService.get_list(data)
        .then(resp => {
          this.data.system = "";
          if (resp.data.status === 'success') {
            this.option_data = resp.data.result;
          } else {
            // console.log('FUNC, reset_changes, ERROR::: ', resp.data.message);
          }
        });
    },
    open_edit_sub_mode: function (sub) {
      this.selected_sub = sub;
      let sub_list = ["sub_1", "sub_2", "sub_3", "sub_4", "sub_5"];
      let sub_det = false;
      for (let i in sub_list) {
        if (sub_det) {
          this.data[sub_list[i]] = "";
        }
        if (sub_list[i] === sub) {
          sub_det = true;
        }
      }
      this.edit_data = JSON.parse(JSON.stringify(this.data));
      this.edit_sub_label = this.data[sub].value;
      this.show_sub_edit = true;
      this.$forceUpdate();
    },
    edit_sub_wisdom: function () {
      let new_data = JSON.parse(
        JSON.stringify(this.edit_data[this.selected_sub])
      );
      let data = {};
      if (this.selected_sub === "sub_1") {
        data = this.option_data.male_anatomy.system.sub[this.data.system.value];
      }
      if (this.selected_sub === "sub_2") {
        data = this.option_data.male_anatomy.system.sub[this.data.system.value]
          .sub[this.data.sub_1.value];
      }
      if (this.selected_sub === "sub_3") {
        data = this.option_data.male_anatomy.system.sub[this.data.system.value]
          .sub[this.data.sub_1.value].sub[this.data.sub_2.value];
      }
      if (this.selected_sub === "sub_4") {
        data = this.option_data.male_anatomy.system.sub[this.data.system.value]
          .sub[this.data.sub_1.value].sub[this.data.sub_2.value].sub[
            this.data.sub_3.value
          ];
      }
      if (this.selected_sub === "sub_5") {
        data = this.option_data.male_anatomy.system.sub[this.data.system.value]
          .sub[this.data.sub_1.value].sub[this.data.sub_2.value].sub[
            this.data.sub_3.value
          ].sub[this.data.sub_4.value];
      }
      //
      let new_list = [];
      for (let i in data.list) {
        if (data.list[i].value === this.edit_sub_label) {
          new_list.push(new_data);
        } else {
          new_list.push(data.list[i]);
        }
      }
      data.list = new_list;
      if (data.sub[this.edit_sub_label]) {
        data.sub[new_data.value] = JSON.parse(
          JSON.stringify(data.sub[this.edit_sub_label])
        );
        this.$delete(data.sub, this.edit_sub_label);
      }
      this.data[this.selected_sub] = new_data;
      this.show_sub_edit = false;
      this.$forceUpdate();
    },
    open_edit_system_mode: function () {
      let sub_list = ["sub_1", "sub_2", "sub_3", "sub_4", "sub_5"];
      for (let i in sub_list) {
        this.data[sub_list[i]] = "";
      }
      this.edit_data = JSON.parse(JSON.stringify(this.data));
      this.edit_label = this.data.system.value;
      this.show_system_edit = true;
    },
    edit_system_wisdom: function () {
      let new_data = JSON.parse(JSON.stringify(this.edit_data.system));
      for (let i in this.option_data.male_anatomy.system.list) {
        if (
          this.option_data.male_anatomy.system.list[i].value === this.edit_label
        ) {
          this.option_data.male_anatomy.system.list[i] = new_data;
        }
      }
      if (this.edit_label !== new_data.value) {
        this.option_data.male_anatomy.system.sub[new_data.value] = JSON.parse(
          JSON.stringify(
            this.option_data.male_anatomy.system.sub[this.edit_label]
          )
        );
        this.$delete(this.option_data.male_anatomy.system.sub, this.edit_label);
      } else {
        this.option_data.male_anatomy.system.sub[this.edit_label].label =
          new_data.label;
        this.option_data.male_anatomy.system.sub[this.edit_label].value =
          new_data.value;
      }
      this.data.system = new_data;
      this.show_system_edit = false;
      this.$forceUpdate();
    },
    bring_select: function (sub_ind) {
      let x = parseInt(sub_ind.split("_")[1]);
      let str = "option_data.male_anatomy.system";
      for (let k = 1; k < x; k++) {
        str += ".sub[sub_" + k.toString() + ".value]";
      }
      str += ".list";
      return (
        '<v-select v-model="' + sub_ind + '" :options="' + str + '"</v-select>'
      );
    },
    add_sub_wisdom: function () {
      let upper_data = {};
      let data = {};
      if (this.selected_sub === "sub_1") {
        upper_data = this.option_data.male_anatomy.system.sub;
        if (upper_data[this.data.system.value] === undefined) {
          upper_data[this.data.system.value] = JSON.parse(
            JSON.stringify(this.data.system)
          );
          upper_data[this.data.system.value].list = [];
          upper_data[this.data.system.value].sub = {};
        }
        data = this.option_data.male_anatomy.system.sub[this.data.system.value];
      }
      if (this.selected_sub === "sub_2") {
        upper_data = this.option_data.male_anatomy.system.sub[
          this.data.system.value
        ].sub;
        if (upper_data[this.data.sub_1.value] === undefined) {
          upper_data[this.data.sub_1.value] = JSON.parse(
            JSON.stringify(this.data.sub_1)
          );
          upper_data[this.data.sub_1.value].list = [];
          upper_data[this.data.sub_1.value].sub = {};
        }
        data = this.option_data.male_anatomy.system.sub[this.data.system.value]
          .sub[this.data.sub_1.value];
      }
      if (this.selected_sub === "sub_3") {
        upper_data = this.option_data.male_anatomy.system.sub[
          this.data.system.value
        ].sub[this.data.sub_1.value].sub;
        if (upper_data[this.data.sub_2.value] === undefined) {
          upper_data[this.data.sub_2.value] = JSON.parse(
            JSON.stringify(this.data.sub_2)
          );
          upper_data[this.data.sub_2.value].list = [];
          upper_data[this.data.sub_2.value].sub = {};
        }
        data = this.option_data.male_anatomy.system.sub[this.data.system.value]
          .sub[this.data.sub_1.value].sub[this.data.sub_2.value];
      }
      if (this.selected_sub === "sub_4") {
        upper_data = this.option_data.male_anatomy.system.sub[
          this.data.system.value
        ].sub[this.data.sub_1.value].sub[this.data.sub_2.value].sub;
        if (upper_data[this.data.sub_3.value] === undefined) {
          upper_data[this.data.sub_3.value] = JSON.parse(
            JSON.stringify(this.data.sub_3)
          );
          upper_data[this.data.sub_3.value].list = [];
          upper_data[this.data.sub_3.value].sub = {};
        }
        data = this.option_data.male_anatomy.system.sub[this.data.system.value]
          .sub[this.data.sub_1.value].sub[this.data.sub_2.value].sub[
            this.data.sub_3.value
          ];
      }
      if (this.selected_sub === "sub_5") {
        upper_data = this.option_data.male_anatomy.system.sub[
          this.data.system.value
        ].sub[this.data.sub_1.value].sub[this.data.sub_2.value].sub[
          this.data.sub_3.value
        ].sub;
        if (upper_data[this.data.sub_4.value] === undefined) {
          upper_data[this.data.sub_4.value] = JSON.parse(
            JSON.stringify(this.data.sub_4)
          );
          upper_data[this.data.sub_4.value].list = [];
          upper_data[this.data.sub_4.value].sub = {};
        }
        data = this.option_data.male_anatomy.system.sub[this.data.system.value]
          .sub[this.data.sub_1.value].sub[this.data.sub_2.value].sub[
            this.data.sub_3.value
          ].sub[this.data.sub_4.value];
      }
      let det = false;
      for (let i in data.list) {
        if (
          data.list[i].label === this.new_system.label ||
          data.list[i].value === this.new_system.value ||
          data.list[i].label === this.new_system.label
        ) {
          det = true;
          alert("Kayıtlı sistemler listesinde mevcuttur.");
        }
      }
      if (!det) {
        data.list.push(this.new_system);
      }
      let lbl = this.new_system.value;
      if (data.sub[lbl] === undefined) {
        data.sub[lbl] = {};
      }
      data.sub[lbl] = {
        caption: this.new_system.label,
        label: this.new_system.value,
        sub: {},
        list: []
      };
      this.data[this.selected_sub] = JSON.parse(
        JSON.stringify(this.new_system)
      );
      this.show_new_sub = false;
      this.$forceUpdate();
    },
    add_system_wisdom: function () {
      let det = false;
      for (let i in this.option_data.male_anatomy.system.list) {
        if (
          this.option_data.male_anatomy.system.list[i].label ===
          this.new_system.label ||
          this.option_data.male_anatomy.system.list[i].value ===
          this.new_system.value ||
          this.option_data.male_anatomy.system.list[i].label ===
          this.new_system.label
        ) {
          det = true;
          alert("Kayıtlı sistemler listesinde mevcuttur.");
        }
      }
      if (!det) {
        let lbl = this.new_system.value;
        this.option_data.male_anatomy.system.list.push(this.new_system);
        this.option_data.male_anatomy.system.sub[lbl] = JSON.parse(
          JSON.stringify(this.new_system)
        );
        this.option_data.male_anatomy.system.sub[lbl].list = [];
        this.option_data.male_anatomy.system.sub[lbl].sub = {};
        this.data.system = JSON.parse(JSON.stringify(this.new_system));
      }
      this.$forceUpdate();
    },
    add_system: function () {
      let det = false;
      for (let i in this.option_data.male_anatomy.system.list) {
        if (
          this.option_data.male_anatomy.system.list[i].label ===
          this.new_system.label
        ) {
          det = true;
        }
      }
      if (!det) {
        for (let a = 1; a < 100000; a++) {
          let lbl = a.toString();
          let label_det = false;
          for (let z in this.option_data.male_anatomy.system.list) {
            if (this.option_data.male_anatomy.system.list[z].value === lbl) {
              label_det = true;
              break;
            }
          }
          if (!label_det) {
            this.new_system.translation.tr = this.new_system.label;
            this.new_system.translation.en = this.new_system.label;
            this.new_system.value = lbl;
            this.option_data.male_anatomy.system.list.push(this.new_system);
            this.option_data.male_anatomy.system.sub[lbl] = {
              caption: lbl,
              translation: { tr: lbl, en: lbl },
              label: lbl,
              list: [],
              sub: {}
            };
            break;
          }
        }
      }
      this.$forceUpdate();
    },
    param_change_func: function () {
      if (this.param_change) {
        if (this.param_change.id) {
          this.param_change.id = 0;
        } else {
          this.param_change.id = 1;
        }
      }
    },
    f_motherChildren: function (op) {
      if (this.mother_children[op]) {
        this.mother_children[op] = 0;
      } else {
        this.mother_children[op] = 1;
      }
    }
  },
  watch: {
    'selected_anatomy_parent.value': function () {
      this.f_motherChildren('forceUpdate');
    },
    'selected_anatomy_region.value': function () {
      this.f_motherChildren('forceUpdate');
    },
    selected_parent: function () {
      if (this.selected_parent) {
        this.selected_anatomy_parent.label = this.selected_parent.label;
        this.selected_anatomy_parent.value = this.selected_parent.value;
        this.selected_anatomy_parent.translation = { tr: "", en: "" };
        this.selected_anatomy_parent.translation.tr = this.selected_parent.translation.tr;
        this.selected_anatomy_parent.translation.en = this.selected_parent.translation.en;
        this.param_change_func();
      }
    },
    selected_sub: function () {
      let sub_list = ["sub_1", "sub_2", "sub_3", "sub_4", "sub_5"];
      let det = false;
      for (let i in sub_list) {
        if (det) {
          this.data[sub_list[i]] = "";
        }
        if (sub_list[i] === this.selected_sub) {
          det = true;
        }
      }
    },
    "data.system": function () {
      let sub_list = ["sub_1", "sub_2", "sub_3", "sub_4", "sub_5"];
      for (let i in sub_list) {
        this.data[sub_list[i]] = "";
      }
    },
    "data.sub_1": function () {
      let sub_list = ["sub_2", "sub_3", "sub_4", "sub_5"];
      for (let i in sub_list) {
        this.data[sub_list[i]] = "";
      }
    },
    "data.sub_2": function () {
      let sub_list = ["sub_3", "sub_4", "sub_5"];
      for (let i in sub_list) {
        this.data[sub_list[i]] = "";
      }
    },
    "data.sub_3": function () {
      let sub_list = ["sub_4", "sub_5"];
      for (let i in sub_list) {
        this.data[sub_list[i]] = "";
      }
    },
    "data.sub_4": function () {
      let sub_list = ["sub_5"];
      for (let i in sub_list) {
        this.data[sub_list[i]] = "";
      }
    }
  },
  components: {
    vSelect
  }
};

</script>

<style>


</style>

